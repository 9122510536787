import React from "react"
import PropTypes from "prop-types"
import { Box, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import {MessageOutlined as MessageIcon} from "@mui/icons-material";
import Marquee from "react-fast-marquee";

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "row",
    height: "40px",
    alignItems: "center",
    margin: "8px 0",
  },
  message: {
    height: "100%",
    flexGrow: 1,
    border: "1px solid #000",
    alignItems: "center",
    display: "flex",
  }
})

const RootNotificationView = (props) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Box style={{width: "200px", height: "100%", display: "flex", flexDirection: "row", padding: "0 16px", backgroundColor: "#8d8d8d", color: "white", alignItems: "center"}}>
        <MessageIcon />
        <Typography>お知らせ</Typography>
      </Box>
      <Marquee className={classes.message} delay={1} speed={40} loop={3} onFinish={props.onLoopFinish}>
        <Typography>メンテナンスのお知らせ：2022年7月27日10:00よりメンテナンスを実施いたします。</Typography>
      </Marquee>
    </Box>
  )
}

RootNotificationView.propTypes = {
  className: PropTypes.string,
  onLoopFinish: PropTypes.func,
}

export default RootNotificationView
