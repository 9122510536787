import { First, Query } from "./carto"
import { RangeType } from "../views/root/data/state"

export const predictionModeValues = [
  { label: "グローバル", value: "global" },
  { label: "朝方", value: "mor" },
  { label: "昼間", value: "day" },
  { label: "夕方", value: "eve" },
  { label: "夜間", value: "nig" },
  { label: "未成年", value: "stud" },
  { label: "成人", value: "adlt" },
  { label: "高齢者", value: "elde" },
]

export default new (class {
  constructor() {}

  getFilterConditions = (filter) => {
    let wheres = []
    if (filter.timeslot) {
      wheres.push(`trim(day_night_jp) = '${filter.timeslot}'`)
    }
    if (filter.age) {
      wheres.push(`trim(personal_injury_all_age) = '${filter.age}'`)
    }
    if (filter.type) {
      wheres.push(`trim(accident_type_jp) = '${filter.type}'`)
    }
    if (filter.injury) {
      wheres.push(`trim(personal_injury_all) = '${filter.injury}'`)
    }
    return wheres
  }

  getBoundsConditions = (
    rangeType,
    bounds,
    column = "the_geom_webmercator"
  ) => {
    if (rangeType === RangeType.Overall) {
      return "TRUE"
    }

    let latlngs = []
    latlngs.push(`${bounds[0].lng} ${bounds[0].lat}`)
    latlngs.push(`${bounds[0].lng} ${bounds[1].lat}`)
    latlngs.push(`${bounds[1].lng} ${bounds[1].lat}`)
    latlngs.push(`${bounds[1].lng} ${bounds[0].lat}`)
    latlngs.push(`${bounds[0].lng} ${bounds[0].lat}`)
    return `
    ST_Contains(ST_Transform(ST_SetSRID(ST_MakePolygon('LINESTRING(${latlngs.join(
      ","
    )})'),4326),3857),${column})
    `
  }

  selectQueryPop = (mode) => {
    let column
    if (mode === "global") {
      column = "global"
    } else {
      column = `pop_${mode}`
    }
    return `
    SELECT data.*, ${column} as pop FROM ${process.env.REACT_APP_TABLE_POP} AS data
    INNER JOIN ${process.env.REACT_APP_TABLE_GYOSEIKU} AS admin
    ON ST_Intersects(admin.the_geom_webmercator, data.the_geom_webmercator)
    WHERE admin.n03_007 = '${process.env.REACT_APP_CITYCODE}'
    `
  }

  selectQueryGakku = () => {
    return `
      SELECT * FROM a27_2016_elementary_polygon
      WHERE a27_005 = '${process.env.REACT_APP_CITYCODE}'
    `
  }

  selectQueryGyoseikai = () => {
    return `
    SELECT * FROM admin_boundaries
    `
  }

  selectQueryDourojikoyosoku = (mode_column = "global", options = {}) => {
    return `SELECT acc_${mode_column}_risk_reg as risk, * FROM ${process.env.REACT_APP_TABLE_PREDICTION_DOURO}`
  }

  selectQueryAverageYosoku = (
    rangeType,
    bounds,
    mode_column = "global",
    options = {}
  ) => {
    if (rangeType === RangeType.ViewBounds && !bounds) {
      return null
    }

    let wheres = [
      this.getBoundsConditions(rangeType, bounds, "data.the_geom_webmercator"),
    ]

    return `
    SELECT AVG(risk) AS avg FROM (
                                   SELECT acc_${mode_column}_risk_reg AS risk
                                   FROM ${
                                     process.env
                                       .REACT_APP_TABLE_PREDICTION_KOSATEN
                                   } AS data
                                   WHERE ${wheres.join(" AND ")}
                                   UNION ALL
                                   SELECT acc_${mode_column}_risk_reg AS risk
                                   FROM ${
                                     process.env
                                       .REACT_APP_TABLE_PREDICTION_DOURO
                                   } AS data
                                   WHERE ${wheres.join(" AND ")}
                                 ) AS foo
    `
  }

  selectQueryKosatenjikoyosoku = (mode_column = "global", options = {}) => {
    return `SELECT 
       acc_${mode_column}_risk_reg as risk, data.* 
    FROM ${process.env.REACT_APP_TABLE_PREDICTION_KOSATEN} AS data
    `
  }

  selectQueryJikojisseki = (filter, options = {}) => {
    let wheres = this.getFilterConditions(filter)
    wheres.push(`city_code = ${process.env.REACT_APP_KEISATSUCHO_CITY_CODE}`)
    wheres.push(
      `prefecture_code = ${process.env.REACT_APP_KEISATSUCHO_PREF_CODE}`
    )

    return `
    SELECT * FROM ${process.env.REACT_APP_TABLE_JISSEKI}
    WHERE ${wheres.join(" AND ")}
    `
  }

  selectQueryTotalCount = (filter, rangeType, bounds = null) => {
    if (rangeType === RangeType.ViewBounds && !bounds) {
      return null
    }

    let wheres = this.getFilterConditions(filter)
    wheres.push(this.getBoundsConditions(rangeType, bounds))
    wheres.push(`city_code = ${process.env.REACT_APP_KEISATSUCHO_CITY_CODE}`)
    wheres.push(
      `prefecture_code = ${process.env.REACT_APP_KEISATSUCHO_PREF_CODE}`
    )

    return `
      SELECT
        COUNT(*) AS count
      FROM ${process.env.REACT_APP_TABLE_JISSEKI}
      WHERE ${wheres.join(" AND ")}
    `
  }

  selectQueryAgePieChartData = (
    filter,
    rangeType,
    bounds = null,
    limit = 5
  ) => {
    if (rangeType === RangeType.ViewBounds && !bounds) {
      return null
    }

    let wheres = this.getFilterConditions(filter)
    wheres.push(this.getBoundsConditions(rangeType, bounds))
    wheres.push(`city_code = ${process.env.REACT_APP_KEISATSUCHO_CITY_CODE}`)
    wheres.push(
      `prefecture_code = ${process.env.REACT_APP_KEISATSUCHO_PREF_CODE}`
    )

    return `
    SELECT * FROM (
      SELECT
        personal_injury_all_age AS label,
        COUNT(*) AS value
      FROM ${process.env.REACT_APP_TABLE_JISSEKI}
      WHERE ${wheres.join(" AND ")}
      GROUP BY personal_injury_all_age        
    ) AS foo
    ORDER BY value DESC 
    ${limit ? " LIMIT " + limit : ""}
    `
  }

  selectQueryInjuryPieChartData = (
    filter,
    rangeType,
    bounds = null,
    limit = 5
  ) => {
    if (rangeType === RangeType.ViewBounds && !bounds) {
      return null
    }

    let wheres = this.getFilterConditions(filter)
    wheres.push(this.getBoundsConditions(rangeType, bounds))
    wheres.push(this.getBoundsConditions(rangeType, bounds))
    wheres.push(`city_code = ${process.env.REACT_APP_KEISATSUCHO_CITY_CODE}`)
    wheres.push(
      `prefecture_code = ${process.env.REACT_APP_KEISATSUCHO_PREF_CODE}`
    )

    return `
    SELECT * FROM (
      SELECT
        personal_injury_all AS label,
        COUNT(*) AS value
      FROM ${process.env.REACT_APP_TABLE_JISSEKI}
      WHERE ${wheres.join(" AND ")}
      GROUP BY personal_injury_all        
    ) AS foo
    ORDER BY value DESC 
    ${limit ? " LIMIT " + limit : ""}
    `
  }

  selectQueryPredictDouroData = (
    mode,
    rangeType,
    bounds = null,
    count = 10,
    min = 0.1,
    max = 0.9
  ) => {
    if (rangeType === RangeType.ViewBounds && !bounds) {
      return null
    }

    let wheres = [this.getBoundsConditions(rangeType, bounds)]
    return `
    WITH cte AS (
      SELECT
        WIDTH_BUCKET(
            acc_${mode}_risk_reg,
            ${min}, ${max}, ${count}
          ) AS num,
        COALESCE(ST_Length(the_geom,true),0) AS len
      FROM ${process.env.REACT_APP_TABLE_PREDICTION_DOURO}
      WHERE ${wheres.join(" AND ")}
    )
    SELECT
      num,
      ROUND(SUM(len)*100)/100 AS value
    FROM cte
    GROUP BY num
    ORDER BY num
    `
  }

  selectQueryPredictKosatenData = (
    mode,
    rangeType,
    bounds = null,
    count = 10,
    min = 0.1,
    max = 0.9
  ) => {
    if (rangeType === RangeType.ViewBounds && !bounds) {
      return null
    }

    let wheres = [this.getBoundsConditions(rangeType, bounds)]
    return `
    WITH cte AS (
      SELECT
        WIDTH_BUCKET(
            acc_${mode}_risk_reg,
            ${min}, ${max}, ${count}
          ) AS num
      FROM ${process.env.REACT_APP_TABLE_PREDICTION_KOSATEN}
      WHERE ${wheres.join(" AND ")}
    )
    SELECT
      num,
      COUNT(*) AS value
    FROM cte
    GROUP BY num
    ORDER BY num
    `
  }

  getDouroPredictionListQuery = (limit) => {
    return `
    SELECT
        ROW_NUMBER() OVER() AS rn,
        *
    FROM (
        SELECT
               cartodb_id,
            TO_CHAR(acc_global_risk_reg,'9.00000') AS risk,
            road_name,
            CONCAT(city_name, oaza_name, koaza, gaiku) AS address,
            CASE zone30
                WHEN 'non-designated' THEN '非該当'
                WHEN 'designated' THEN '該当'
                ELSE ''
            END AS zone30,
            speed,
            speed_median::INT AS speed_median,
            manager_jp,
            pt0_2020,
            ST_Y(ST_Centroid(the_geom)) AS latitude,
            ST_X(ST_Centroid(the_geom)) AS longitude
        FROM ${process.env.REACT_APP_TABLE_PREDICTION_DOURO}
        WHERE speed_median <> 'NaN'
        ORDER BY acc_global_risk_reg DESC
        ${limit ? `LIMIT ${limit}` : ""}
    ) AS foo
    ORDER BY risk DESC
    `
  }

  getKosatenPredictionListQuery = (limit) => {
    return `
    SELECT
        ROW_NUMBER() OVER() AS rn,
        *
    FROM (
      SELECT
             cartodb_id,
        TO_CHAR(acc_global_risk_reg,'9.00000') AS risk,
        road_name,
        CONCAT(city_name, oaza_name, koaza, gaiku) AS address,
        CASE zone30
         WHEN 'non-designated' THEN '非該当'
         WHEN 'designated' THEN '該当'
         ELSE ''
        END AS zone30,
        speed,
        speed_median::INT AS speed_median,
        '' AS manager_jp,
         pt0_2020,
             ST_Y(the_geom) AS latitude,
             ST_X(the_geom) AS longitude,
        null AS intersection_name             
      FROM ${process.env.REACT_APP_TABLE_PREDICTION_KOSATEN}
      WHERE speed_median <> 'NaN'
      ORDER BY acc_global_risk_reg DESC
      ${limit ? `LIMIT ${limit}` : ""}
    ) AS foo
    ORDER BY risk DESC
    `
  }

  getJissekiDetailContent = (cartodbId, _ = "", debug = false) => {
    return new Promise((resolve, reject) => {
      First(`
        SELECT
          accident_type_jp,
          TO_CHAR(TO_DATE(case_ymd, 'YYYYMMDD'), 'YYYY年MM月DD日') as case_ymd,
          day_night_jp,
          road_code_jp,
          road_formation_jp,
          topography_jp,
          weather_jp,
          road_condition_jp,
          personal_injury_all,
          personal_injury_all_age
        FROM ${process.env.REACT_APP_TABLE_JISSEKI}
        WHERE cartodb_id = ${cartodbId}
        `)
        .then((res) => {
          resolve(`
            <h3>道路実績データ</h3>
            <table class="popup_road_detail" cellpadding=0 cellspacing=1>
            <tr><th>事故種類</th><td>${res["accident_type_jp"]}</td></tr>
            <tr><th>発生日</th><td>${res["case_ymd"]}</td></tr>
            <tr><th>時間帯</th><td>${res["day_night_jp"]}</td></tr>
            <tr><th>道路種</th><td>${res["road_code_jp"]}</td></tr>
            <tr><th>発生場所</th><td>${res["road_formation_jp"]}</td></tr>
            <tr><th>環境</th><td>${res["topography_jp"]}</td></tr>
            <tr><th>天気</th><td>${res["weather_jp"]}</td></tr>
            <tr><th>路面状態</th><td>${res["road_condition_jp"]}</td></tr>
            <tr><th>けがの程度</th><td>${res["personal_injury_all"]}</td></tr>
            <tr><th>けが人の年齢帯</th><td>${res["personal_injury_all_age"]}</td></tr>
            </table>
`)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  getKosaitenDetailContent = (cartodbId, mode = "global", debug = false) => {
    return new Promise((resolve, reject) => {
      let model = (
        predictionModeValues.filter((v) => v.value === mode) ?? []
      ).pop()
      First(`
    SELECT
      cartodb_id,
      acc_${mode}_risk_reg AS risk,
      acc_global_risk_reg AS _global_risk,
      acc_${mode} AS acc,
      acc_global,
      speed_limit_lower,
      speed_median,
      pop_global AS pop,
      pt0_2020 AS pt0,
      ptc_2020 AS ptc,
      lane_count,
      fine_elev,
      sudden_acceleration,
      wobble,
      forward_collision_warning
    FROM ${process.env.REACT_APP_TABLE_PREDICTION_KOSATEN}
    WHERE cartodb_id = ${cartodbId}
    `)
        .then((res) => {
          let risk = parseFloat(res["risk"]).toFixed(2)
          if (risk === "-0.00") {
            risk = "0.00"
          }
          let acc = parseInt(res["acc"]).toLocaleString()
          let speedLimit = res["speed_limit_lower"] + "km/h"
          let speedMedian = res["speed_median"] + "km/h"
          let pop = res["pop"]
          let pt0 = parseInt(res["pt0"]).toLocaleString()
          let ptc = parseInt(res["ptc"]).toLocaleString()
          let laneCount = res["lane_count"]
          let fineElev = res["fine_elev"] + "m"
          let suddenAcceleration = `${parseInt(res["sudden_acceleration"])}回`
          if (
            !res["sudden_acceleration"] ||
            parseInt(res["sudden_acceleration"]) < 2
          ) {
            suddenAcceleration = "-- 回"
          }
          let wobble = `${parseInt(res["wobble"])}回`
          if (!res["wobble"] || parseInt(res["wobble"]) < 2) {
            wobble = "-- 回"
          }
          let forwardCollisionWarning = `${parseInt(
            res["forward_collision_warning"]
          )}回`
          if (
            !res["forward_collision_warning"] ||
            parseInt(res["forward_collision_warning"]) < 2
          ) {
            forwardCollisionWarning = "-- 回"
          }

          if (mode !== "global") {
            risk += `(${parseFloat(res["_global_risk"]).toFixed(2)})`
            acc += `(${parseInt(res["acc_global"]).toLocaleString()})`
          }

          resolve(`
        <div class="map_pop_window">
        <div class="title">交差点事故予測 <span class="notice">${
          mode !== "global" ? "（）はグローバル" : ""
        }</span></div>
        <table class="popup_road_detail" cellpadding=0 cellspacing=1>
        <tr><th>事故発生リスク</th><td>${risk}</td></tr>
        <tr><th>事故発生件数</th><td>${acc}</td></tr>
        <tr><th>法定速度</th><td>${speedLimit}</td></tr>
        <tr><th>走行速度</th><td>${speedMedian}</td></tr>
        <tr><th>人流データから求めた<br>人口指標</th><td>${pop}</td></tr>
        <tr><th>2020年国勢調査総人口<br>（小地域）</th><td>${pt0}</td></tr>
        <tr><th>2020年国勢調査高齢者人口<br>（小地域）</th><td>${ptc}</td></tr>
        <tr><th>車線数</th><td>${laneCount}</td></tr>
        <tr><th>標高</th><td>${fineElev}</td></tr>
        <tr><th>急ブレーキ</th><td>${suddenAcceleration}</td></tr>
        <tr><th>ふらつき</th><td>${wobble}</td></tr>
        <tr><th>前方衝突警告</th><td>${forwardCollisionWarning}</td></tr>
        </table>
        </div>
        `)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  getDouroDetailContent = (cartodbId, mode = "global", debug = false) => {
    return new Promise((resolve, reject) => {
      let model = (
        predictionModeValues.filter((v) => v.value === mode) ?? []
      ).pop()
      First(`
    SELECT
      cartodb_id,
      acc_${mode}_risk_reg AS risk,
      acc_global_risk_reg AS _global_risk,
      acc_${mode} AS acc,
           acc_global,
      manager_jp,
      speed_median,
      signal_dist_rank,
      pop_global AS pop,
      pop_global,
      pt0_2020 AS pt0,
      ptc_2020 AS ptc,
      lane_count,
      width,
      fine_elev,
      forward_collision_warning
    FROM ${process.env.REACT_APP_TABLE_PREDICTION_DOURO}
    WHERE cartodb_id = ${cartodbId}
    `)
        .then((res) => {
          let risk = parseFloat(res["risk"]).toFixed(2)
          if (risk === "-0.00") {
            risk = "0.00"
          }
          let acc = parseInt(res["acc"]).toLocaleString()
          let managerJp = res["manager_jp"]
          let speedMedian = res["speed_median"] + "km/h"
          let signalDistRank = res["signal_dist_rank"]
          let pop = res["pop"]
          let pt0 = parseInt(res["pt0"]).toLocaleString()
          let ptc = parseInt(res["ptc"]).toLocaleString()
          let laneCount = res["lane_count"]
          let width = res["width"] + "m"
          let fineElev = res["fine_elev"] + "m"
          let forwardCollisionWarning =
            parseInt(res["forward_collision_warning"]) + "回"
          if (
            !res["forward_collision_warning"] ||
            parseInt(res["forward_collision_warning"]) < 2
          ) {
            forwardCollisionWarning = "-- 回"
          }

          if (mode !== "global") {
            risk += `(${parseFloat(res["_global_risk"]).toFixed(2)})`
            acc += `(${parseInt(res["acc_global"]).toLocaleString()})`
            //            pop += `(${res['pop_global']})`
          }

          resolve(`
        <div class="map_pop_window">
        <div class="title">道路事故予測 <span class="notice">${
          mode !== "global" ? "（）はグローバル" : ""
        }</span></div>
        <table class="popup_road_detail" cellpadding=0 cellspacing=1>
        <tr><th>事故発生リスク</th><td>${risk}</td></tr>
        <tr><th>事故発生件数</th><td>${acc}</td></tr>
        <tr><th>道路管理者</th><td>${managerJp}</td></tr>
        <tr><th>走行速度</th><td>${speedMedian}</td></tr>
        <tr><th>信号のある交差点<br>からの距離指標</th><td>${signalDistRank}</td></tr>
        <tr><th>人流データから求めた<br>人口指標</th><td>${pop}</td></tr>
        <tr><th>2020年国勢調査<br>総人口(小地域)</th><td>${pt0}</td></tr>
        <tr><th>2020年国勢調査<br>高齢者人口(小地域)</th><td>${ptc}</td></tr>
        <tr><th>車線数</th><td>${laneCount}</td></tr>
        <tr><th>道路幅員</th><td>${width}</td></tr>
        <tr><th>標高</th><td>${fineElev}</td></tr>
        <tr><th>前方衝突警告</th><td>${forwardCollisionWarning}</td></tr>
        </table>
        </div>
        `)
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  getAllCommentQuery = `
  SELECT
         ST_Y(the_geom) AS latitude,
         ST_X(the_geom) AS longitude,
         *
  FROM ${process.env.REACT_APP_TABLE_COMMENT}
  WHERE delete_flg <> 1
  `

  deleteComment = (cartodbId) => {
    return new Promise((resolve, reject) => {
      Query(`
      DELETE FROM ${process.env.REACT_APP_TABLE_COMMENT}
      WHERE cartodb_id = ${cartodbId}
      `)
        .then(() => {
          resolve()
        })
        .catch((e) => {
          reject(e)
        })
    })
  }

  editComment = (latlng, comment, type, targetCartodbId, cartodbId) => {
    return new Promise((resolve, reject) => {
      if (cartodbId) {
        Query(`
        UPDATE ${process.env.REACT_APP_TABLE_COMMENT}
        SET
            description = '${comment
              .replaceAll("'", "''")
              .replaceAll("\n", "<br>")}',
            type = '${type}',
            target_cartodb_id = ${targetCartodbId ?? "NULL"}
        WHERE cartodb_id = ${cartodbId}
        `)
          .then((res) => {
            resolve(res)
          })
          .catch((e) => {
            reject(e)
          })
      } else {
        Query(`
        INSERT INTO ${
          process.env.REACT_APP_TABLE_COMMENT
        } (the_geom, description, type, target_cartodb_id) VALUES
        (
         ST_SetSRID(ST_MakePoint(${latlng.lng}, ${latlng.lat}),4326),
         '${comment.replaceAll("'", "''").replaceAll("\n", "<br>")}',
         '${type}',
         ${targetCartodbId ?? "NULL"}
        )
        `)
          .then((res) => {
            resolve(res)
          })
          .catch((e) => {
            reject(e)
          })
      }
    })
  }
})()
