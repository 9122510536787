import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@mui/styles"
import LogoImage from "../../../../../resources/logo.png"
import { Box } from "@mui/material"

const useStyles = makeStyles({
  root: {
    backgroundColor: "inherit",
  },
  image: {
    width: "calc(100% - 32px)",
    padding: "8px 16px",
  }
})

const RootLogoView = (props) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <img className={classes.image} src={LogoImage} alt="ロゴ" />
    </Box>
  )
}

RootLogoView.propTypes = {
  className: PropTypes.string,
}

export default RootLogoView
