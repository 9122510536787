import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@mui/styles"
import { Box, Typography } from "@mui/material"

const useStyles = makeStyles({
  root: {
    backgroundColor: "inherit",
  },
})

const RootPredictCardView = (props) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Typography>予測グラフ {props.type}</Typography>
    </Box>
  )
}

RootPredictCardView.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
}

export default RootPredictCardView
