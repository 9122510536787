import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"
import { Box, Button, Divider, IconButton, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import * as L from "leaflet/dist/leaflet"
import "leaflet/dist/leaflet.css"
import { RootDataContext } from "../../index"
import DataManager from "../../../../manager/data"
import DouroLegends from "./legend/douro"
import KousatenLegends from "./legend/kousaten"
import JissekiLegends from "./legend/jiseki"
import JinryuLegends from "./legend/jinryu"
import "leaflet-pegman/leaflet-pegman.min"
import "leaflet-pegman/leaflet-pegman.css"
import "./road_detail_table.css"
import CartoQueryLayer from "../../../../manager/cartoQueryLayer"

import markerIcon from "leaflet/dist/images/marker-icon.png"
import markerIcon2x from "leaflet/dist/images/marker-icon-2x.png"
import markerIconShadow from "leaflet/dist/images/marker-shadow.png"
import MapComment from "./comment"

import "./pegman.css"

L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIcon,
  shadowUrl: markerIconShadow,
})

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
    position: "relative",
    display: "flex",
    flexDirection: "column",
  },
  map: {
    overflow: "hidden",
    width: "100%",
    flexGrow: 1,
  },
  streetView: {
    flexGrow: 1,
    height: "80px",
    position: "relative !important",
  },
  legend: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    position: "absolute",
    left: "12px",
    top: "12px",
    zIndex: "1000",
  },
  addPoint: {
    position: "absolute",
    top: "64px",
    right: "11px",
    width: "46px",
    height: "46px",
    zIndex: 3000,
    backgroundColor: "white",
    border: "2px solid rgba(0,0,0,0.2)",
    borderRadius: "5px",
  },
  addPointButton: {
    color: "white",
  },
  addPointButtonIcon: {
    width: "100%",
    height: "100%",
  },
})

const baseLayers = {
  ダークモード: L.tileLayer(
    "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png",
    {}
  ),
  "地理院タイル(標準)": L.tileLayer(
    "https://cyberjapandata.gsi.go.jp/xyz/std/{z}/{x}/{y}.png",
    { tileSize: 256, maxNativeZoom: 18, maxZoom: 22 }
  ),
  "地理院タイル(淡色)": L.tileLayer(
    "https://cyberjapandata.gsi.go.jp/xyz/pale/{z}/{x}/{y}.png",
    { tileSize: 256, maxNativeZoom: 18, maxZoom: 22 }
  ),
  "地理院タイル(写真)": L.tileLayer(
    "https://cyberjapandata.gsi.go.jp/xyz/seamlessphoto/{z}/{x}/{y}.jpg",
    { tileSize: 256, maxNativeZoom: 18, maxZoom: 22 }
  ),
}

let mapInitializeTimer = null
let mapInitialized = false
let clickActionTimer = null

const MapView = (props) => {
  const classes = useStyles()
  const mapRef = useRef()
  const buttonRef = useRef()
  const {
    state,
    setViewDataType,
    setMapViewBounds,
    setMapCenter,
    addLoadingCount,
    subLoadingCount,
    setCommentEditMode,
    setCommentEditData,
  } = useContext(RootDataContext)
  const [map, setMap] = useState()
  const [clickJissekiData, setClickJissekiData] = useState()
  const [clickDouroData, setClickDouroData] = useState()
  const [clickKosatenData, setClickKosatenData] = useState()
  const [clickMapData, setClickMapData] = useState()
  const [hideLegends, setHideLegends] = useState(false)
  const [commentSelectData, setCommentSelectData] = useState()

  useEffect(() => {
    if (!mapRef?.current || mapInitialized) {
      return
    }

    clearTimeout(mapInitializeTimer)
    mapInitializeTimer = setTimeout(() => {
      let m = L.map(mapRef.current, { zoomControl: false })
      m.setView(
        [
          parseFloat(process.env.REACT_APP_MAP_INITIAL_LATITUDE),
          parseFloat(process.env.REACT_APP_MAP_INITIAL_LONGITUDE),
        ],
        parseInt(process.env.REACT_APP_MAP_INITIAL_ZOOM)
      )

      m.on("load moveend", () => {
        const bounds = m.getBounds()
        setMapViewBounds([bounds.getNorthEast(), bounds.getSouthWest()])

        let center = m.getCenter()
        center.zoom = m.getZoom()
        setMapCenter(center)
      })

      L.control.layers(baseLayers).addTo(m)
      L.control.zoom({ position: "bottomright" }).addTo(m)

      let pegman = new L.Control.Pegman({
        position: "bottomright",
        theme: "leaflet-pegman-v3-small",
        panoDiv: "#panorama",
        apiKey: "",
      })

      pegman
        .addTo(m)
        .on("svpc-dropzone-drop", () => {
          console.log("dropped")
        })
        .on("svpc_streetview-shown", () => {
          console.log("streetview shown")
          setHideLegends(true)
          setCommentEditMode(false)
        })
        .on("svpc_streetview-hidden", () => {
          console.log("streetview hidden")
          setHideLegends(false)
        })

      setMap(m)
    }, 100)

    return () => {
      map?.off()
      map?.remove()
      setMap(null)
      mapInitialized = false
    }
  }, [mapRef])

  useEffect(() => {
    if (!map) {
      return
    }
    map.addLayer(baseLayers["地理院タイル(淡色)"])

    setTimeout(() => {
      map.invalidateSize()
    }, 100)

    return () => {
      map.removeLayer(baseLayers["地理院タイル(淡色)"])
    }
  }, [map])

  useEffect(() => {
    if (!map) {
      return
    }
    map.on("click", (e) => {
      setClickMapData(e)
    })
  }, [map, state.isCommentEditMode])

  useEffect(() => {
    if (!map || !state.mapCenter) {
      return
    }
    if (
      state.mapCenter.lat.toFixed(5) == map.getCenter().lat.toFixed(5) &&
      state.mapCenter.lng.toFixed(5) == map.getCenter().lng.toFixed(5)
    ) {
      return
    }

    map.flyTo([state.mapCenter.lat, state.mapCenter.lng], state.mapCenter.zoom)
  }, [map, state.mapCenter])

  useEffect(() => {
    if (!map || !state.pinLocation) {
      return
    }

    let marker = L.marker(state.pinLocation, {
      icon: new L.Icon({
        iconUrl: markerIcon,
        iconSize: [25, 41],
        iconAnchor: [12, 41],
      }),
    }).addTo(map)

    return () => {
      map.removeLayer(marker)
    }
  }, [map, state.pinLocation])

  useEffect(() => {
    clearTimeout(clickActionTimer)
    clickActionTimer = setTimeout(() => {
      if (
        !clickJissekiData &&
        !clickDouroData &&
        !clickKosatenData &&
        !clickMapData
      ) {
        return
      }
      let type
      let cartodbId
      let latlng
      let func
      if (clickJissekiData) {
        cartodbId = clickJissekiData.data.cartodb_id
        latlng = clickJissekiData.latLng
        type = "jisseki"
        func = DataManager.getJissekiDetailContent
      } else if (clickKosatenData) {
        cartodbId = clickKosatenData.data.cartodb_id
        latlng = clickKosatenData.latLng
        type = "kosaten"
        func = DataManager.getKosaitenDetailContent
      } else if (clickDouroData) {
        cartodbId = clickDouroData.data.cartodb_id
        latlng = clickDouroData.latLng
        type = "douro"
        func = DataManager.getDouroDetailContent
      } else if (state.isCommentEditMode && clickMapData) {
        console.log(clickMapData)
        cartodbId = null
        latlng = clickMapData.latlng
        type = "map"
        func = null
      }

      if (state.isCommentEditMode) {
        setCommentSelectData({
          target_cartodb_id: cartodbId,
          latitude: latlng.lat,
          longitude: latlng.lng,
          type: type,
        })
        return
      }

      if (cartodbId && latlng && func && state.predictionMode) {
        L.popup()
          .setLatLng(latlng)
          .setContent("<div>読込中・・・</div>")
          .openOn(map)

        func(cartodbId, state.predictionMode).then((content) => {
          L.popup().setLatLng(latlng).setContent(content).openOn(map)
        })
      }

      setClickJissekiData(null)
      setClickDouroData(null)
      setClickKosatenData(null)
      setClickMapData(null)
    }, 100)
  }, [
    state.predictionMode,
    clickJissekiData,
    clickKosatenData,
    clickDouroData,
    clickMapData,
  ])

  useEffect(() => {
    if (!map) {
      return
    }
    map?.invalidateSize()
  }, [state.visibleRightPane])

  return (
    <Box className={props.className + " " + classes.root}>
      <Box ref={mapRef} className={classes.map}></Box>
      <Box id="panorama" className={classes.streetView} />
      {!hideLegends && (
        <MapComment map={map} selectedData={commentSelectData} />
      )}
      <CartoQueryLayer
        map={map}
        cartoCss={`
              #layer {
               polygon-fill: ramp([pop], (#ecda9a, #f1b973, #f7945d, #f86f56, #ee4d5a), jenks());
              }
              #layer::outline {
               line-width: 0;
               line-color: #FFFFFF;
               line-opacity: 0.5;
              }              
              `}
        zIndex={1003}
        opacity={0.6}
        hidden={!state.viewData.includes("人流・人口")}
        query={DataManager.selectQueryPop(state.predictionMode)}
        onLoading={() => addLoadingCount(30000)}
        onLoadFinished={() => subLoadingCount()}
      />
      <CartoQueryLayer
        map={map}
        cartoCss={`
          #layer::outline {
            line-width: 1;
            line-color: #6c6c6c;
            line-opacity: 0.5;
          }  
          `}
        query={DataManager.selectQueryGyoseikai()}
        hidden={!state.viewData.includes("行政界")}
        zIndex={1000}
        onLoading={() => addLoadingCount(30000)}
        onLoadFinished={() => subLoadingCount()}
      />
      <CartoQueryLayer
        map={map}
        cartoCss={`
           #layer {
             polygon-fill: #6dba7f;
             polygon-opacity: 0.9;
           }
           #layer::outline {
             line-width: 1;
             line-color: #FFFFFF;
             line-opacity: 0.5;
           }
           #layer::labels {
             text-name: [a27_007];
             text-face-name: 'Unifont Medium';
             text-size: 11;
             text-fill: #000000;
             text-label-position-tolerance: 0;
             text-halo-radius: 1;
             text-halo-fill: #ffffff;
             text-dy: -10;
             text-allow-overlap: true;
             text-placement: point;
             text-placement-type: dummy;
           }          
          `}
        query={DataManager.selectQueryGakku()}
        hidden={!state.viewData.includes("小学校学区")}
        opacity={0.6}
        zIndex={1002}
        onLoading={() => addLoadingCount(30000)}
        onLoadFinished={() => subLoadingCount()}
      />
      <CartoQueryLayer
        map={map}
        cartoCss={`
          #layer {
            line-color: ramp([risk], (#1a9641, #a6d96a, #ffffbf, #fdae61, #d7191c), jenks());
          }
          #layer1[zoom<=14] {
            line-width: 1.5;
          }
          #layer1[zoom=15] {
            line-width: 1.7;
          }
          #layer1[zoom=16] {
            line-width: 2;
          }
          #layer1[zoom=17] {
            line-width: 3;  
          }
          #layer1[zoom>17] {
            line-width: 5;  
          }              
        `}
        query={DataManager.selectQueryDourojikoyosoku(state.predictionMode)}
        hidden={!state.viewData.includes("道路事故予測")}
        featureClickColumns={["cartodb_id"]}
        onFeatureClick={setClickDouroData}
        zIndex={1010}
        onLoading={() => addLoadingCount(30000)}
        onLoadFinished={() => subLoadingCount()}
      />
      <CartoQueryLayer
        map={map}
        cartoCss={`
              #layer {
                marker-fill: ramp([risk], (#1a9641, #a6d96a, #ffffbf, #fdae61, #d7191c), jenks());
                marker-fill-opacity: 1;
                marker-allow-overlap: true;
                marker-line-width: 1;
                marker-line-color: #FFFFFF;
                marker-line-opacity: 1;
              }
              
              #layer1[zoom<=14] {
                marker-width: 5;
              }
              #layer1[zoom=15] {
                marker-width: 9;
              }
              #layer1[zoom=16] {
                marker-width: 10;
              }
              #layer1[zoom=17] {
                marker-width: 11;
              }
              #layer1[zoom>17] {
                marker-width: 15;
              }   
              `}
        query={DataManager.selectQueryKosatenjikoyosoku(state.predictionMode)}
        zIndex={1020}
        hidden={!state.viewData.includes("交差点事故予測")}
        featureClickColumns={["cartodb_id"]}
        onFeatureClick={setClickKosatenData}
        onLoading={() => addLoadingCount(30000)}
        onLoadFinished={() => subLoadingCount()}
      />
      <CartoQueryLayer
        map={map}
        cartoCss={`
            #layer {
                marker-fill: #422fbc;
                marker-fill-opacity: 0.9;
                marker-allow-overlap: true;
                marker-line-width: 1;
                marker-line-color: #FFFFFF;
                marker-line-opacity: 1;
            }
            #layer1[zoom<=14] {
              marker-width: 12.5;
            }
            #layer1[zoom=15] {
              marker-width: 13;
            }
            #layer1[zoom=16] {
              marker-width: 14;
            }
            #layer1[zoom=17] {
              marker-width: 15;
            }
            #layer1[zoom>17] {
              marker-width: 18;
            }               
            `}
        query={DataManager.selectQueryJikojisseki({
          timeslot: state.timeslotFilter,
          age: state.ageFilter,
          type: state.typeFilter,
          injury: state.injuryFilter,
        })}
        featureClickColumns={["cartodb_id"]}
        onFeatureClick={setClickJissekiData}
        zIndex={1030}
        hidden={!state.viewData.includes("事故実績")}
        onLoading={() => addLoadingCount(30000)}
        onLoadFinished={() => subLoadingCount()}
      />

      {!hideLegends && (
        <Box className={classes.legend}>
          {state.viewData.includes("道路事故予測") && <DouroLegends />}
          {state.viewData.includes("交差点事故予測") && <KousatenLegends />}
          {state.viewData.includes("人流・人口") && <JinryuLegends />}
          {state.viewData.includes("事故実績") && <JissekiLegends />}
        </Box>
      )}
    </Box>
  )
}

MapView.propTypes = {
  className: PropTypes.string,
  mapOptions: PropTypes.any,
}

export default MapView
