import PropTypes from "prop-types"
import { makeStyles } from "@mui/styles"
import React, { useContext, useMemo, useRef, useState } from "react"
import { Box, Button, Menu, MenuItem, Typography } from "@mui/material"
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
  Check as CheckIcon,
} from "@mui/icons-material"

const useStyles = makeStyles({
  root: {
    textAlign: "center",
    color: "black !important",
  },
  arrowIcon: {
    position: "absolute",
    right: "12px",
    color: "#3c6960",
  },
})

const RootFilterSelectorView = (props) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const buttonRef = useRef()
  const keyValues = useMemo(
    () => Object.fromEntries(props.values.map((e) => [e.value, e.label])),
    []
  )
  const textColor = useMemo(() => {
    if (!keyValues) {
      return "black"
    }
    if (keyValues[props.value]) return "black"
    return "#707070"
  }, [props.value, keyValues])
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Box className={props.className + " " + classes.root}>
      <Button
        id={`selector_${props.key}`}
        style={{ position: "relative", width: "100%" }}
        ref={buttonRef}
        onClick={handleOpen}
      >
        <Typography variant="subtitle2" style={{ color: textColor }}>
          {keyValues[props.value] ?? props.name}
        </Typography>
        {open && (
          <ArrowDropUpIcon fontSize="large" className={classes.arrowIcon} />
        )}
        {!open && (
          <ArrowDropDownIcon fontSize="large" className={classes.arrowIcon} />
        )}
      </Button>
      <Menu
        anchorEl={buttonRef.current}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": props.key,
        }}
        PaperProps={{
          style: {
            width: "180px",
          },
        }}
      >
        <MenuItem
          onClick={() => {
            props.onChange(null)
            handleClose()
          }}
        >
          <em>選択解除</em>
        </MenuItem>
        {props.values.map((e, i) => {
          return (
            <MenuItem
              key={i}
              onClick={() => {
                props.onChange(e.value)
                handleClose()
              }}
            >
              {e.label}
              {props.value === e.value && (
                <CheckIcon fontSize="small" style={{ marginLeft: "8px" }} />
              )}
            </MenuItem>
          )
        })}
      </Menu>
    </Box>
  )
}

RootFilterSelectorView.propTypes = {
  className: PropTypes.string,
  key: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  values: PropTypes.array,
  onChange: PropTypes.func,
}

export default RootFilterSelectorView
