import { Box } from "@mui/material"
import TitleView from "./title"
import PredictionModeSelectorView from "./predictionModeSelector"
import RangeTypeSelectorView from "./rangeTypeSelector"
import React from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@mui/styles"

const useStyles = makeStyles({
  root: {
    backgroundColor: "#f3f3f3",
    display: "flex",
    flexDirection: "row",
  },
})

const RootHeaderView = (props) => {
  const classes = useStyles()

  return (
    <Box className={props.className + " " + classes.root}>
      <TitleView style={{ flexGrow: 1 }} />
      <RangeTypeSelectorView />
      <PredictionModeSelectorView />
    </Box>
  )
}

RootHeaderView.propTypes = {
  className: PropTypes.string,
}

export default RootHeaderView
