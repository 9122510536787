import PropTypes from "prop-types"
import { Box } from "@mui/material"
import OptionView from "../../option"
import AggregateCardView from "./aggregateCard"
import PredictCardView from "./predictCard"
import React, { useContext } from "react"
import { makeStyles } from "@mui/styles"
import classNames from "classnames"
import PredictKousatenCard from "./predictCard/kousaten"
import PredictDouroCard from "./predictCard/douro"
import PredictAverageCard from "./predictCard/average"
import { RootDataContext } from "../../index"

const useStyles = makeStyles({
  root: {
    color: "white",
    display: "flex",
    flexDirection: "column",
  },
  card: {
    margin: "5px 0",
  },
})

const RootRightPanelView = (props) => {
  const classes = useStyles()
  const { state } = useContext(RootDataContext)

  return (
    <Box className={props.className + " " + classes.root}>
      {/* 右ペイン */}
      <Box style={{ display: "flex", flexDirection: "row" }}>
        <Box style={{ flexGrow: 1 }} />
      </Box>
      {state.viewData.includes("事故実績") && <AggregateCardView />}
      <Box>
        {(state.viewData.includes("道路事故予測") ||
          state.viewData.includes("交差点事故予測")) && (
          <PredictAverageCard classname={classes.card} />
        )}
        {state.viewData.includes("道路事故予測") && (
          <PredictDouroCard className={classes.card} />
        )}
        {state.viewData.includes("交差点事故予測") && (
          <PredictKousatenCard className={classes.card} />
        )}
      </Box>
    </Box>
  )
}

RootRightPanelView.propTypes = {
  className: PropTypes.string,
}

export default RootRightPanelView
