import React, { useContext, useRef, useState } from "react"
import PropTypes from "prop-types"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  TextField,
  Typography,
} from "@mui/material"
import { makeStyles } from "@mui/styles"
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon,
} from "@mui/icons-material"

const useStyles = makeStyles({
  root: {
    marin: 0,
    width: "100vw",
    height: "100vh",
    overflow: "hidden",
    backgroundColor: "#f3f3f3",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  title: {
    color: "#1e1e1e",
  },
  sub_title: {
    color: "#1e1e1e",
  },
  inputField: {
    width: "250px",
  },
})

const LoginView = (props) => {
  const classes = useStyles()
  const userIdRef = useRef()
  const passwordRef = useRef()

  const [openErrorDialog, setOpenErrorDialog] = useState(false)
  const [showPassword, setShowPassword] = useState(false)

  const handleShowPassword = () => {
    setShowPassword(!showPassword)
  }

  const handleCloseDialog = () => {
    setOpenErrorDialog(false)
  }

  const handleLogin = () => {
    if (
      userIdRef.current.value === process.env.REACT_APP_LOGIN_USERID &&
      passwordRef.current.value === process.env.REACT_APP_LOGIN_PASSWORD
    ) {
      sessionStorage.setItem("user_uid", "guest")
      props.onLogin()
    } else setOpenErrorDialog(true)
  }

  return (
    <Box className={classes.root}>
      <Typography variant="h7" className={classes.title}>
        自治体EXPO
      </Typography>
      <Typography variant="h7" className={classes.title}>
        交通事故予測
      </Typography>
      <Box sx={{ m: 1 }} />
      <TextField
        className={classes.inputField}
        variant="outlined"
        label="ユーザID"
        size="small"
        inputRef={userIdRef}
      />
      <TextField
        className={classes.inputField}
        variant="outlined"
        label="パスワード"
        size="small"
        type={showPassword ? "text" : "password"}
        style={{ marginTop: "12px" }}
        InputProps={{
          endAdornment: (
            <IconButton onClick={handleShowPassword}>
              {showPassword ? (
                <VisibilityIcon fontSize="small" />
              ) : (
                <VisibilityOffIcon fontSize="small" />
              )}
            </IconButton>
          ),
        }}
        inputRef={passwordRef}
      />
      <Button style={{ color: "#4d4d4d", fontSize: "12px" }}>
        ユーザID、パスワードを忘れた
      </Button>
      <Button
        onClick={handleLogin}
        style={{ color: "white", backgroundColor: "#3c6960", width: "120px" }}
      >
        ログイン
      </Button>
      <Dialog onClose={handleCloseDialog} open={openErrorDialog}>
        <DialogContent>ユーザIDまたはパスワードが間違っています</DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={handleCloseDialog}>OK</Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

LoginView.propTypes = {
  className: PropTypes.string,
  onLogin: PropTypes.func,
}

export default LoginView
