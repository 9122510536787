import React, {useContext, useEffect, useState} from "react"
import PropTypes from "prop-types"
import { Autocomplete, Box, TextField, Typography } from "@mui/material"
import { Rows } from "../../../../../manager/carto"
import {RootDataContext} from "../../../index";

const RootPlaceSearchView = (props) => {
    const {setMapCenter } = useContext(RootDataContext)
  const [options, setOptions] = useState([])

  useEffect(() => {
    Rows(`
    SELECT
      cartodb_id,
      ST_Y(ST_Transform(ST_Centroid(the_geom_webmercator),4326)) AS latitude,
      ST_X(ST_Transform(ST_Centroid(the_geom_webmercator),4326)) AS longitude,
      a27_007 AS label
    FROM a27_2016_elementary_polygon
    WHERE a27_005 = '38205'
    `).then((rows) => {
      setOptions(rows)
    })
  }, [])

  return (
    <Autocomplete
      disablePortal
      size="small"
      onChange={(e, v) => {
          setMapCenter({lat: v.latitude, lng: v.longitude, zoom: 15})
      }}
      renderOption={(props, option) => (
        <Box component="li" {...props}>
          <Typography variant="caption">{option.label}</Typography>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          size="small"
          label="学区検索"
          color="primary"
          InputProps={{ ...params.InputProps, style: { fontSize: 12 } }}
          InputLabelProps={{ style: { fontSize: 12 } }}
        />
      )}
      options={options}
    />
  )
}

RootPlaceSearchView.propTypes = {
  className: PropTypes.string,
}

export default RootPlaceSearchView
