import React, { useContext, useRef } from "react"
import PropTypes from "prop-types"
import { useEffect, useMemo, useState } from "react"
import * as L from "leaflet/dist/leaflet"
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
  Menu,
  Snackbar,
  TextareaAutosize,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material"
import {
  MessageOutlined as MessageOutlinedIcon,
  Message as MessageIcon,
} from "@mui/icons-material"
import { makeStyles } from "@mui/styles"
import { RootDataContext } from "../../../index"
import DataManager from "../../../../../manager/data"
import { Query, Rows } from "../../../../../manager/carto"

const commentMarkerIcon = L.icon({
  iconUrl: require("../../../../../resources/marker/comment_marker.png"),
  iconRetinaUrl: require("../../../../../resources/marker/comment_marker_2x.png"),
  iconSize: [32, 32],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32],
})

const yubiMarkerIcon = L.icon({
  iconUrl: require("../../../../../resources/marker/yubi_24.png"),
  iconSize: [32, 32],
  iconAnchor: [4, 28],
  popupAnchor: [16, -24],
})

const useStyles = makeStyles({
  editButtonBase: {
    position: "absolute",
    bottom: "143px",
    right: "9px",
    width: "34px",
    height: "34px",
    border: "2px solid rgba(0,0,0,0.2)",
    backgroundColor: "white",
    zIndex: 3000,
    borderRadius: "5px",
  },
  editButton: {
    color: "#888",
  },
  comment_edit_notice: {
    position: "absolute",
    top: "8px",
    left: 0,
    right: 0,
    margin: "0 auto",
    backgroundColor: "white",
    border: "2px solid rgba(0,0,0,0.2)",
    borderRadius: "8px",
    width: "400px",
    height: "38px",
    zIndex: 4000,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
})

const RootMapCommentLayer = (props) => {
  const classes = useStyles()
  const { state, setViewDataVisible, setCommentEditMode, openStateDialog } =
    useContext(RootDataContext)
  const [openEditDialog, setOpenEditDialog] = useState(false)
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)
  const [editDialogTitle, setEditDialogTitle] = useState()
  const [value, setValue] = useState()
  const [targetData, setTargetData] = useState()
  const enableCommentView = useMemo(
    () => state.viewData.includes("メモ"),
    [state.viewData]
  )
  const [data, setData] = useState()
  const [deleteProcess, setDeleteProcess] = useState(0)
  const [updated, setUpdated] = useState(false)
  const [openSubmitSuccess, setOpenSubmitSuccess] = useState(false)
  const [openSubmitFailure, setOpenSubmitFailure] = useState(false)
  const [deletedCartodbId, setDeletedCartdbId] = useState([])

  const layerGroup = useMemo(() => {
    if (!props.map) {
      return
    }
    return L.layerGroup(null, {
      zIndexOffset: 1500,
    }).addTo(props.map)
  }, [props.map])

  useEffect(() => {
    return () => {
      props.map?.removeLayer(layerGroup)
    }
  },[])

  useEffect(() => {
    if (!props.selectedData) {
      return setTargetData(null)
    }
    setTargetData({ ...props.selectedData })
  }, [props.selectedData])

  useEffect(() => {
    console.log("[EnableCommentView]", "update", enableCommentView)
    updateMarkers()
  }, [enableCommentView])

  useEffect(() => {
    console.log("[Update]", "open dialog", props)
    props.map?.closePopup()
  }, [openEditDialog])

  useEffect(() => {
    window.addEventListener("click", clickEventListener)

    return () => {
      window.removeEventListener("click", clickEventListener)
      props.map?.closePopup()
    }
  }, [])

  useEffect(() => {
    props.map?.closePopup()
    loadData()

    if (state.isCommentEditMode && !state.viewData.includes("メモ")) {
      setViewDataVisible("メモ")
    }
  }, [state.isCommentEditMode])

  useEffect(() => {
    if (!targetData) {
      return
    }

    let popup = L.popup().setLatLng([targetData.latitude, targetData.longitude])

    if (targetData.description) {
      let contents = `
          <div>${targetData.description}</div>      
      `
      if (state.isCommentEditMode) {
        contents += `
          <div>
          <button id="comment_popup_edit_item" data='${JSON.stringify(
            targetData
          )}'>編集</button>
          <button id="comment_popup_delete_item" data='${JSON.stringify(
            targetData
          )}'>削除</button>
          </div>        
        `
      }
      popup.setContent(contents)
    } else {
      let title
      switch (targetData.type) {
        case "jisseki":
          title = "事故実績へのメモ"
          break
        case "douro":
          title = "道路事故予測へのメモ"
          break
        case "kosaten":
          title = "交差点事故予測へのメモ"
          break
        default:
          title = "この地点へのメモ"
      }
      popup.setContent(`
          <div>${title}</div>
          <div><button id="comment_popup_edit_item">追加</button></div>          
          `)
    }

    popup.openOn(props.map)

    return () => {
      props.map.closePopup()
    }
  }, [targetData])

  const handleCloseEditDialog = () => {
    setOpenEditDialog(false)
  }

  const handleAddComment = () => {
    let d = !data ? [] : Array.from(data)
    console.log(targetData)
    if (targetData.dataIndex !== undefined) {
      d[targetData.dataIndex] = { ...targetData, description: value }
    } else {
      let v = { ...targetData, description: value, dataIndex: d.length }
      d.push(v)
    }
    setUpdated(true)
    setData(d)
    setValue(null)
    setOpenEditDialog(false)
  }

  useEffect(() => {
    if (!targetData) {
      return
    }
    console.log("Delete", targetData, value)
    if (targetData.cartodb_id) {
      let n = Array.from(deletedCartodbId)
      n.push(targetData.cartodb_id)
      setDeletedCartdbId(n)
      setUpdated(true)
    }

    let d = !data ? [] : Array.from(data)
    d.splice(targetData.dataIndex, 1)
    console.log(d)
    setData(d)
  }, [deleteProcess])

  useEffect(() => {
    if (!data) { return }

    props.map?.closePopup()
    setOpenEditDialog(false)
    setOpenDeleteDialog(false)
    updateMarkers()
  }, [data])

  const handleCommentChanged = (e) => {
    setValue(e.target.value)
  }

  const handleUpdatePosition = (dataIndex, latlng) => {
    let d = Array.from(data)

    console.log("[update position]", d[dataIndex], latlng)
    setUpdated(true)
    d[dataIndex].latitude = latlng.lat
    d[dataIndex].longitude = latlng.lng
    setData(d)
  }

  const toggleEditMode = () => {
    if (state.isCommentEditMode && updated) {
      openStateDialog({
        title: "変更があります",
        content: (
          <Typography>
            編集モードを終了すると未保存の変更はすべて破棄されます。<br />本当によろしいですか
          </Typography>
        ),
        actions: [
          {
            title: "変更を破棄して編集モードを終了",
            onClick: () => {
              setCommentEditMode(false)
            },
          },
          {
            title: "キャンセル",
          },
        ],
      })
    } else {
      setCommentEditMode(!state.isCommentEditMode)
    }
  }

  const clickEventListener = (e) => {
    let target
    if (e.target?.attributes?.data?.value) {
      target = JSON.parse(e.target.attributes.data.value)
    }

    switch (e.srcElement.id) {
      case "comment_popup_edit_item":
        console.log(e, props)
        props.map?.closePopup()
        setOpenEditDialog(true)
        break
      case "comment_popup_delete_item":
        setDeleteProcess(deleteProcess + 1)
        break
      case "marker_edit_item":
        console.log("[Edit]", e, data)
        break
      default:
        break
    }
  }

  const loadData = () => {
    setValue(null)
    setDeletedCartdbId([])
    setUpdated(false)
    Rows(DataManager.getAllCommentQuery).then((rows) => {
      setData(
        rows.map((v, i) => {
          v.dataIndex = i
          return v
        })
      )
    })
  }

  const updateMarkers = () => {
    layerGroup?.clearLayers()

    if (!data || !enableCommentView || !props.map) {
      return
    }

    data.map((row, i) => {
      let m = L.marker([row["latitude"], row["longitude"]], {
        icon: yubiMarkerIcon,
        draggable: state.isCommentEditMode,
        dataIndex: i,
      })
      m.on("click", () => {
        setTargetData({
          ...row,
          dataIndex: i,
        })
      })
      m.on("dragend", (e) => {
        handleUpdatePosition(e.target.options.dataIndex, m.getLatLng())
        //        console.log(e, m.getLatLng())
      })
      m.addTo(layerGroup)
    })
  }

  const handleCancelCommentData = () => {
    if (updated) {
      openStateDialog({
        title: "変更を元に戻しますか",
        content: (<Typography>もとに戻すと未保存の変更はすべて破棄されます。<br />本当によろしいですか</Typography>),
        actions: [
          {
            title: "変更を破棄して元に戻す",
            onClick: () => {
              console.log("On cancel")
              loadData()
              setUpdated(false)
            },
          },
          {
            title: "キャンセル",
          },
        ],
      })
    }
  }

  const handleSubmitCommentData = () => {
    // すべてのマーカーの状態を取得してcartodb_idがあればupdate、なければinsert文を生成
    let queries = data.map((v) => {
      if (v.cartodb_id) {
        return `
        UPDATE ${process.env.REACT_APP_TABLE_COMMENT}
        SET 
          the_geom = ST_SetSRID(ST_MakePoint(${v.longitude}, ${
          v.latitude
        }),4326),
          description = '${v.description
            .replace("'", "''")
            .replace("\n", "<br>")}',
          type = '${v.type}',
          target_cartodb_id = ${v.target_cartodb_id ?? "NULL"},
          delete_flg = ${v.delete_flg ?? 0}
        WHERE cartodb_id = ${v.cartodb_id}
        `
      } else {
        return `
        INSERT INTO ${
          process.env.REACT_APP_TABLE_COMMENT
        } (the_geom, description, type, target_cartodb_id, delete_flg)
        VALUES (
          ST_SetSRID(ST_MakePoint(${v.longitude},${v.latitude}),4326),
          '${v.description.replace("'", "''").replace("\n", "<br>")}',
          '${v.type}',
          ${v.target_cartodb_id},
          ${v.delete_flg ?? 0}
        )
        `
      }
    })
    if (deletedCartodbId && deletedCartodbId.length > 0) {
      queries.push(
        `UPDATE ${
          process.env.REACT_APP_TABLE_COMMENT
        } SET delete_flg = 1 WHERE cartodb_id IN (${deletedCartodbId.join(
          ","
        )})`
      )
    }

    console.log("Update queries", queries)

    Query(queries.join(";"))
      .then((res) => {
        setOpenSubmitSuccess(true)
        setTimeout(() => {
          setOpenSubmitSuccess(false)
        }, 1500)
        loadData()
      })
      .catch((e) => {
        setOpenSubmitFailure(true)
        setTimeout(() => {
          setOpenSubmitFailure(false)
        }, 5000)
        loadData()
      })

    setUpdated(false)
  }

  const handleSubmitSuccessClose = () => {
    setOpenSubmitSuccess(false)
  }
  const handleSubmitFailureClose = () => {
    setOpenSubmitFailure(false)
  }

  return (
    <>
      <Box
        className={classes.editButtonBase}
        style={{
          backgroundColor: state.isCommentEditMode ? "#9e9e9e" : "#fff",
        }}
      >
        <IconButton onClick={toggleEditMode} size="small">
          {state.isCommentEditMode ? (
            <MessageIcon
              className={classes.editButton}
              fontSize="small"
              style={{ color: "white" }}
            />
          ) : (
            <MessageOutlinedIcon
              className={classes.editButton}
              fontSize="small"
            />
          )}
        </IconButton>
      </Box>
      {state.isCommentEditMode && (
        <Box className={classes.comment_edit_notice}>
          <Typography variant="h8" style={{ marginLeft: "16px" }}>
            メモ編集モード
          </Typography>
          <Box style={{ flexGrow: 1 }} />
          <Button
            onClick={handleSubmitCommentData}
            size="small"
            disabled={!updated}
          >
            保存
          </Button>
          <Button
            onClick={handleCancelCommentData}
            size="small"
            disabled={!updated}
          >
            元に戻す
          </Button>
          <Box style={{ margin: "0 8px" }} />
          <Button
              onClick={toggleEditMode}
              size="small"
          >モード終了</Button>
          <Box style={{ margin: "0 8px" }} />
        </Box>
      )}
      <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
        <DialogTitle>{editDialogTitle}</DialogTitle>
        <DialogContent>
          <TextareaAutosize
            onChange={handleCommentChanged}
            minRows={5}
            placeholder="メモを入力"
            defaultValue={targetData?.description ?? ""}
            style={{ width: "100%" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddComment}>登録</Button>
          <Button onClick={handleCloseEditDialog}>キャンセル</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSubmitSuccess}
        onClose={handleSubmitSuccessClose}
      >
        <Alert onClose={handleSubmitSuccessClose} severity="info">
          保存しました
        </Alert>
      </Snackbar>
      <Snackbar
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
        open={openSubmitFailure}
        onClose={handleSubmitFailureClose}
      >
        <Alert onClose={handleSubmitFailureClose} severity="error">
          保存に失敗しました
        </Alert>
      </Snackbar>
    </>
  )
}

RootMapCommentLayer.propTypes = {
  map: PropTypes.any,
  selectedData: PropTypes.any,
}

export default RootMapCommentLayer
