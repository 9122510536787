import React, { useContext, useState } from "react"
import LeftPanelView from "./leftPanel"
import { Box } from "@mui/material"
import HeaderView from "./header"
import MapView from "./map"
import NotificationView from "../notification"
import RightPanelView from "./rightPanel"
import PropTypes from "prop-types"
import { makeStyles } from "@mui/styles"
import { RootDataContext } from "../index"

const useStyles = makeStyles({
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "row",
  },
  rightPanel: {
    width: "300px",
    height: "100%",
    margin: "0 8px",
  },
})

const RootMapContentsView = (props) => {
  const { state } = useContext(RootDataContext)
  const [showNotification, setShowNotification] = useState(true)
  const classes = useStyles()

  return (
    <Box className={classes.root + " " + props.className}>
      <LeftPanelView />
      <Box
        style={{
          flexGrow: 1,
          display: "flex",
          flexDirection: "column",
        }}
      >
        {/* ヘッダー */}
        <HeaderView />
        {/* コンテンツ */}
        <MapView />
      </Box>
      {state.visibleRightPane && (
        <RightPanelView className={classes.rightPanel} />
      )}
    </Box>
  )
}

RootMapContentsView.propTypes = {
  className: PropTypes.string,
}

export default RootMapContentsView
