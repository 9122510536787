import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@mui/styles"
import { Box, Typography } from "@mui/material"
import { RootDataContext } from "../../../index"
import { First, Rows } from "../../../../../manager/carto"
import DataManager from "../../../../../manager/data"
import PieChart from "./pieChart"

const useStyles = makeStyles({
  root: {
    backgroundColor: "#f3f3f3",
    padding: "8px",
    marginBottom: "5px",
  },
  pieBox: {
    margin: "8px",
    display: "flex",
    flexDirection: "row",
    gap: "8px",
  },
  chart: {
    width: "calc(50% - 4px)", // なぜかコレをしないとChartJSが100%で出力される
  },
})

const fillColors = [
  "#cc9",
  "#9cc",
  "#c9c",
  "#99c",
  "#9c9",
  "#c99",
  "#c63",
  "#36c",
  "#93c",
  "#c66",
  "#6c6",
  "#66c",
  "#933",
  "#393",
  "#993",
]

const borderColors = [
  "#a6a67c",
  "#739a9a",
  "#936e93",
  "#6b6b8f",
  "#709670",
  "#936e6e",
  "#964b25",
  "#264c98",
  "#67228a",
  "#9b4e4e",
  "#4e9b4e",
  "#4b4b98",
  "#6b2323",
  "#246c24",
  "#6b6b23",
]

const RootAggregateCardView = (props) => {
  const classes = useStyles()
  const { state } = useContext(RootDataContext)
  const [ageChartData, setAgeChartData] = useState(null)
  const [injuryChartData, setInjuryChartData] = useState(null)
  const [totalCount, setTotalCount] = useState(null)

  useEffect(() => {

    if (!state.mapViewBounds) {
      // TODO: 消す
      return
    }

    First(
      DataManager.selectQueryTotalCount(
        {
          timeslot: state.timeslotFilter,
          age: state.ageFilter,
          type: state.typeFilter,
          injury: state.injuryFilter,
        },
        state.rangeType,
        state.mapViewBounds
      )
    ).then((row) => {
      setTotalCount(parseInt(row["count"]).toLocaleString())
    })

    Rows(
      DataManager.selectQueryInjuryPieChartData(
        {
          timeslot: state.timeslotFilter,
          age: state.ageFilter,
          type: state.typeFilter,
          injury: state.injuryFilter,
        },
        state.rangeType,
        state.mapViewBounds
      )
    ).then((rows) => {
      setInjuryChartData({
        labels: rows.map((v) => v.label),
        datasets: [
          {
            data: rows.map((v) => v.value),
            backgroundColor: rows.map((_, i) => fillColors[i]),
            borderColor: rows.map((_, i) => borderColors[i]),
            borderWidth: 1,
          },
        ],
      })
    })

    Rows(
      DataManager.selectQueryAgePieChartData(
        {
          timeslot: state.timeslotFilter,
          age: state.ageFilter,
          type: state.typeFilter,
          injury: state.injuryFilter,
        },
        state.rangeType,
        state.mapViewBounds
      )
    ).then((rows) => {
      setAgeChartData({
        labels: rows.map((v) => v.label),
        datasets: [
          {
            data: rows.map((v) => v.value),
            backgroundColor: rows.map((_, i) => fillColors[i]),
            borderColor: rows.map((_, i) => borderColors[i]),
            borderWidth: 1,
          },
        ],
      })
    })
  }, [
    state.rangeType,
    state.mapViewBounds,
    state.timeslotFilter,
    state.ageFilter,
    state.typeFilter,
    state.injuryFilter,
  ])

  return (
    <Box className={classes.root}>
      <Box
        style={{
          margin: "1px",
          backgroundColor: "white",
          borderRadius: "6px",
          padding: "4px",
        }}
      >
        {totalCount && (
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "flex-end",
              marginBottom: "4px",
            }}
          >
            <Typography color="#3c6960" variant="subtitle1">
              事故発生件数：
            </Typography>
            <Typography color="#3c6960" variant="h4">
              {totalCount}
            </Typography>
            <Typography color="#3c6960" variant="subtitle1">
              件
            </Typography>
          </Box>
        )}
        <Box className={classes.pieBox}>
          {ageChartData && (
            <PieChart className={classes.chart} data={ageChartData} />
          )}
          {injuryChartData && (
            <PieChart className={classes.chart} data={injuryChartData} />
          )}
        </Box>
      </Box>
    </Box>
  )
}

RootAggregateCardView.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
}

export default RootAggregateCardView
