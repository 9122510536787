import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@mui/styles"
import { Box, Typography } from "@mui/material"
import { RootDataContext } from "../../../index"
import { First } from "../../../../../manager/carto"

const useStyles = makeStyles({
  root: {
    position: "relative",
    width: "200px",
    height: "70px",
    backgroundColor: "white",
    boxShadow: "2px 2px 3px rgba(0,0,0,.4)",
    borderRadius: "4px",
  },
  title: {
    position: "relative",
    margin: "6px 0 3px 12px !important",
  },
  bar: {
    width: "calc(100% - 32px)",
    height: "9px",
    marginLeft: "16px",
    background:
      "linear-gradient(.25turn, #ecda9a, #f1b973, #f7945d, #f86f56, #ee4d5a)",
    position: "absolute",
    borderRadius: "3px",
  },
  min_label: {
    position: "absolute",
    left: "5px",
    top: "40px",
  },
  max_label: {
    position: "absolute",
    right: "8px",
    top: "40px",
  },
})

const RootMapJinryuLegends = (props) => {
  const classes = useStyles()
  const { state } = useContext(RootDataContext)
  const [min, setMin] = useState("0.0")
  const [max, setMax] = useState("1.0")

  useEffect(() => {
    let column
    if (state.predictionMode === "global") {
      column = "global"
    } else {
      column = `pop_${state.predictionMode}`
    }

    First(`
    SELECT 
      MIN(${column}) AS min,
      MAX(${column}) AS max
    FROM ${process.env.REACT_APP_TABLE_POP}`).then((res) => {
      setMin(parseFloat(res["min"]).toFixed(1))
      setMax(parseFloat(res["max"]).toFixed(1))
    })
  }, [state.predictionMode])

  return (
    <Box className={classes.root}>
      <Typography variant="subtitle2" className={classes.title}>
        人流・人口
      </Typography>
      <Box className={classes.bar} />
      <Typography variant="caption" className={classes.min_label}>
        {min}
      </Typography>
      <Typography variant="caption" className={classes.max_label}>
        {max}
      </Typography>
    </Box>
  )
}

RootMapJinryuLegends.propTypes = {
  className: PropTypes.string,
}

export default RootMapJinryuLegends
