import PropTypes from "prop-types"
import { makeStyles } from "@mui/styles"
import LogoView from "./logo"
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material"
import PlaceSearchView from "./placeSearch"
import FilterSelectorView from "./filterSelector"
import React, { useContext } from "react"
import { RootDataContext } from "../../index"
import {
  Circle as CheckOnIcon,
  CircleOutlined as CheckOffIcon,
} from "@mui/icons-material"

const useStyles = makeStyles({
  root: {
    width: "200px",
    minWidth: "200px",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "5px",
  },
  group: {
    backgroundColor: "#f3f3f3",
    padding: "8px",
  },
  box: {
    backgroundColor: "white",
    margin: "8px 8px",
    height: "30px",
  },
  checkGroup: {
    margin: "0 8px",
  },
})

const timeValues = [
  { label: "昼-明", value: "昼-明" },
  { label: "昼-昼", value: "昼-昼" },
  { label: "昼-暮", value: "昼-暮" },
  { label: "夜-暮", value: "夜-暮" },
  { label: "夜-夜", value: "夜-夜" },
  { label: "夜-明", value: "夜-明" },
]

const ageValues = [
  { label: "0~24歳", value: "0~24歳" },
  { label: "25~34歳", value: "25~34歳" },
  { label: "35~44歳", value: "35~44歳" },
  { label: "45~54歳", value: "45~54歳" },
  { label: "55~64歳", value: "55~64歳" },
  { label: "65~74歳", value: "65~74歳" },
  { label: "75歳以上", value: "75歳以上" },
]

const typeValues = [
  { label: "人対車両", value: "人対車両" },
  { label: "車両相互", value: "車両相互" },
  { label: "車両単独", value: "車両単独" },
]

const injuryValues = [
  { label: "死亡", value: "死亡" },
  { label: "負傷", value: "負傷" },
  { label: "損傷なし", value: "損傷なし" },
  { label: "対象外当事者", value: "対象外当事者" },
]

const viewDataValues = [
  { label: "道路事故予測", value: "道路事故予測" },
  { label: "交差点事故予測", value: "交差点事故予測" },
  { label: "事故実績", value: "事故実績" },
  { label: "行政界", value: "行政界" },
  { label: "小学校学区", value: "小学校学区" },
  { label: "人流・人口", value: "人流・人口" },
  { label: "メモ", value: "メモ" },
]

let checkboxTimer = null

const RootLeftPanelView = (props) => {
  const classes = useStyles()
  const {
    state,
    setTimeslotFilter,
    setAgeFilter,
    setTypeFilter,
    setInjuryFilter,
    toggleViewData,
  } = useContext(RootDataContext)

  return (
    <Box className={classes.root}>
      {/* 左ペイン */}
      <Box className={classes.group}>
        <PlaceSearchView />
      </Box>
      {state.viewData.includes("事故実績") && (
        <Box className={classes.group}>
          <Typography
            variant="subtitle2"
            align="center"
            style={{ marginTop: "4px" }}
          >
            事故実績
          </Typography>
          <Divider
            flexItem={true}
            style={{
              margin: "0 50px 15px 50px",
              borderWidth: "1.5px",
              borderColor: "#3c6960",
              backgroundColor: "#3c6960",
            }}
          />
          <FilterSelectorView
            className={classes.box}
            name="時間"
            values={timeValues}
            value={state.timeslotFilter}
            onChange={(v) => {
              setTimeslotFilter(v)
            }}
          />
          <FilterSelectorView
            className={classes.box}
            name="年齢"
            values={ageValues}
            value={state.ageFilter}
            onChange={(v) => {
              setAgeFilter(v)
            }}
          />
          <FilterSelectorView
            className={classes.box}
            name="事故タイプ"
            values={typeValues}
            value={state.typeFilter}
            onChange={(v) => {
              setTypeFilter(v)
            }}
          />
          <FilterSelectorView
            className={classes.box}
            name="事故程度"
            values={injuryValues}
            value={state.injuryFilter}
            onChange={(v) => {
              setInjuryFilter(v)
            }}
          />
        </Box>
      )}
      <Box className={classes.group}>
        <Typography
          variant="subtitle2"
          align="center"
          style={{ marginTop: "16px" }}
        >
          表示データ選択
        </Typography>
        <Divider
          flexItem={true}
          style={{
            margin: "0 50px 15px 50px",
            borderWidth: "1.5px",
            borderColor: "#3c6960",
            backgroundColor: "#3c6960",
          }}
        />
        <FormGroup className={classes.checkGroup}>
          {viewDataValues.map((e, i) => {
            return (
              <FormControlLabel
                style={{
                  margin: 0,
                  height: "34px",
                }}
                key={i}
                control={
                  <Checkbox
                    style={{ color: "#3c6960", transform: "scale(0.9)" }}
                    checked={state.viewData.includes(e.value)}
                    icon={
                      <CheckOffIcon
                        color="#3c6960 !important"
                        fontSize="small"
                      />
                    }
                    checkedIcon={
                      <CheckOnIcon
                        color="#3c6960 !important"
                        fontSize="small"
                      />
                    }
                  />
                }
                onClick={() => {
                  clearTimeout(checkboxTimer)
                  checkboxTimer = setTimeout(() => {
                    toggleViewData(e.value)
                  }, 10)
                }}
                label={<Typography variant="subtitle2">{e.label}</Typography>}
              />
            )
          })}
        </FormGroup>
      </Box>
    </Box>
  )
}

RootLeftPanelView.propTypes = {
  className: PropTypes.string,
}

export default RootLeftPanelView
