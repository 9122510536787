import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@mui/styles"
import { Box, Typography } from "@mui/material"
import { RootDataContext } from "../../../index"
import { First } from "../../../../../manager/carto"
import DataManager from "../../../../../manager/data"

const useStyles = makeStyles({
  root: {
    backgroundColor: "#f3f3f3",
    color: "black",
    padding: "12px",
  },
  value: {
    textAlign: "center",
    marginTop: "6px",
    padding: "8px",
    backgroundColor: "white",
    borderRadius: "6px",
  },
})

const RootPredictAverageCard = (props) => {
  const classes = useStyles()
  const { state } = useContext(RootDataContext)
  const [average, setAverage] = useState(null)

  useEffect(() => {
    if (!state.mapViewBounds) {
      return
    }
    First(
      DataManager.selectQueryAverageYosoku(
        state.rangeType,
        state.mapViewBounds,
        state.predictionMode
      )
    ).then((row) => {
      if (!row) {
        setAverage("--")
      }
      setAverage(parseFloat(row["avg"]).toFixed(2))
    })
  }, [state.predictionMode, state.rangeType, state.mapViewBounds])

  return (
    <Box className={props.className + " " + classes.root}>
      <Typography>事故発生リスク（平均値）</Typography>
      <Box className={classes.value}>
        {average && (
          <Typography color="#3c6960" variant="h4">
            {average}
          </Typography>
        )}
      </Box>
    </Box>
  )
}

RootPredictAverageCard.propTypes = {
  className: PropTypes.string,
}

export default RootPredictAverageCard
