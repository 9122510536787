import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Box, Button, CircularProgress, setRef } from "@mui/material"
import ListView from "./list"
import MapView from "./map/map"
import { makeStyles } from "@mui/styles"
import UseRootViewData, { ViewType } from "./data"
import NotificationView from "./notification"
import HeaderView from "./map/header"
import LeftPanelView from "./map/leftPanel"
import RightPanelView from "./map/rightPanel"
import OptionView from "./option"
import LogoImage from "../../resources/logo.png"
import LoginView from "../login"
import dayjs from "dayjs"
import MapContents from "./map"
import ListContents from "./list"
import ExportView from "./export"
import StateDialog from "./stateDialog"

const useStyles = makeStyles({
  root: {
    backgroundColor: "white",
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    minWidth: "1600px",
    overflowX: "auto",
  },
  viewSwitchButton: {
    backgroundColor: "#c2c2c2 !important",
    borderRadius: "0 !important",
    color: "white !important",
    width: "165px !important",
    height: "35px !important",
    margin: "auto !important",
  },
  activeButton: {
    backgroundColor: "#3c6960 !important",
  },
  nativeButton: {
    background:
      "linear-gradient(0deg, rgba(117,110,231,1) 0%, rgba(29,29,170,1) 60%, rgba(209,209,255,1) 100%)",
  },
  content: {
    height: "100%",
    display: "flex",
    flexDirection: "row",
    flexGrow: 1,
    gap: "5px",
  },
  mainPanel: {},
  rightPanel: {
    width: "300px",
    height: "100%",
    margin: "0 8px",
  },
})

export const RootDataContext = React.createContext()

const RootView = (props) => {
  const classes = useStyles()

  const useRootData = UseRootViewData()
  const { state, setViewType, setUserUid } = useRootData
  const [showNotification, setShowNotification] = useState(true)
  const [refresh, setRefresh] = useState()
  const [showExport, setShowExport] = useState()

  useEffect(() => {
    setRefresh(dayjs())
  }, [state.userUid])

  return (
    <RootDataContext.Provider value={useRootData}>
      <Box className={props.className + " " + classes.root}>
        <Box
          style={{
            margin: "8px 0",
            display: "flex",
            flexDirection: "row",
            gap: "6px",
            backgroundColor: "#f3f3f3",
          }}
        >
          {/* 上部表示切り替えボタン */}
          <Button
            className={
              (state.viewType === ViewType.map ? classes.activeButton : null) +
              " " +
              classes.viewSwitchButton
            }
            onClick={() => setViewType(ViewType.map)}
          >
            地図
          </Button>
          <Button
            className={
              (state.viewType === ViewType.list ? classes.activeButton : null) +
              " " +
              classes.viewSwitchButton
            }
            onClick={() => setViewType(ViewType.list)}
          >
            リスト
          </Button>
          <Box sx={{ m: 0.5 }} />
          <OptionView
            onLogout={props.onLogout}
            onExport={() => setShowExport(true)}
          />
          <Box style={{ flexGrow: 1 }} />
          <img
            src={LogoImage}
            style={{ height: "30px", margin: "auto", marginRight: "1.5em" }}
          />
        </Box>
        <Box
          style={{
            backgroundColor: "white",
            width: "100%",
            height: "100%",
          }}
        >
          <Box className={classes.content}>
            {state.viewType === ViewType.map && <MapContents />}
            {state.viewType === ViewType.list && <ListContents />}
          </Box>
        </Box>
        {/* お知らせ */}
        {showNotification && (
          <NotificationView
            onLoopFinish={(e, i, j) => {
              //                    console.log("[Marquee]", "onFinish", e, i, j)
              setShowNotification(false)
            }}
          />
        )}
      </Box>
      {state.loadingCount > 0 && (
        <Box
          style={{
            backgroundColor: "rgba(194,193,193,0.12)",
            position: "absolute",
            top: 0,
            left: 0,
            width: "100vw",
            height: "100vh",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 2000,
          }}
        >
          <CircularProgress style={{ color: "#476731" }} />
        </Box>
      )}
      {showExport && (
        <ExportView
          onClose={() => {
            setShowExport(null)
          }}
        />
      )}
      <StateDialog />
    </RootDataContext.Provider>
  )
}

RootView.propTypes = {
  className: PropTypes.string,
  onLogout: PropTypes.func,
}

export default RootView
