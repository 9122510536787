import React, { useEffect, useState } from "react"
import "./App.css"
import RootView from "./views/root"
import LoginView from "./views/login"
import { createTheme } from "@mui/material"
import { ThemeProvider } from "@mui/material/styles"
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom"
import { red } from "@mui/material/colors"

const theme = createTheme({
  palette: {
    primary: {
      main: "#3c6960",
    },
  },
})

const App = () => {
  const [login, setLogin] = useState(
    sessionStorage.getItem("user_uid")
  )

  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              login ? (
                <RootView
                  onLogout={() => {
                    sessionStorage.removeItem("user_uid")
                    setLogin(false)
                  }}
                />
              ) : (
                <LoginView onLogin={() => setLogin(true)} />
              )
            }
          />
        </Routes>
      </Router>
    </ThemeProvider>
  )
}

export default App
