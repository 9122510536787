import React, { useContext } from "react"
import PropTypes from "prop-types"
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material"
import { makeStyles } from "@mui/styles"
import DouroListView from "./douro"
import KosatenListView from "./kosaten"

import "ag-grid-community/dist/styles/ag-grid.css"
import "ag-grid-community/dist/styles/ag-theme-balham.css"
import { RootDataContext } from "../index"
import UseRootViewData from "../data"
import UseListViewData from "./data"
import { ListViewType } from "./data/state"

const useStyles = makeStyles({
  root: {
    backgroundColor: "#7eab55",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    width: "100%",
  },
  list: {
    border: "1px solid #fff",
    flexGrow: 1,
  },
})

export const ListDataContext = React.createContext()

const ListView = (props) => {
  const useListData = UseListViewData()
  const { state, setViewType } = useListData
  const classes = useStyles()

  const handleChange = (e) => {
    setViewType(e.target.value)
  }

  return (
    <ListDataContext.Provider value={useListData}>
      <Box className={classes.root + " ag-theme-alpine"}>
        <Box style={{ display: "flex", flexDirection: "row" }}>
          <ToggleButtonGroup
            value={state.viewType}
            exclusive
            onChange={handleChange}
          >
            <ToggleButton value={ListViewType.douro}>道路事故予測</ToggleButton>
            <ToggleButton value={ListViewType.kousaten}>交差点事故予測</ToggleButton>
          </ToggleButtonGroup>
        </Box>
        {state.viewType === ListViewType.douro && (
          <DouroListView className={classes.list} />
        )}
        {state.viewType === ListViewType.kousaten && (
          <KosatenListView className={classes.list} />
        )}
      </Box>
    </ListDataContext.Provider>
  )
}

ListView.propTypes = {
  className: PropTypes.string,
}

export default ListView
