import React, { useContext, useEffect, useMemo, useRef, useState } from "react"
import PropTypes from "prop-types"
import { AgGridReact } from "ag-grid-react"
import { makeStyles } from "@mui/styles"
import { Box, IconButton, Typography } from "@mui/material"
import { RootDataContext } from "../index"
import LOCALE_JA from "../../../resources/aggrid/locale.ja"
import { Rows } from "../../../manager/carto"
import DataManager from "../../../manager/data"
import { DoubleArrow as DoubleArrowIcon } from "@mui/icons-material"

const useStyles = makeStyles({
  root: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    color: "white",
    margin: "4px !important",
  },
  grid: {
    flexGrow: 1,
  },
})

const RootKosatenListView = (props) => {
  const classes = useStyles()
  const { state, setPinLocation } = useContext(RootDataContext)
  const gridRef = useRef()
  const [rowData, setRowData] = useState(null)

  const columnDef = useMemo(
    () => [
      {
        headerName: "地図",
        cellRenderer: (param) => {
          return (
            <IconButton
              size="small"
              onClick={() => {
                setPinLocation({
                  lat: param.data.latitude,
                  lng: param.data.longitude,
                  cartodb_id: param.data.cartodb_id,
                  type: "kosaten",
                })
              }}
            >
              <DoubleArrowIcon style={{ color: "#666" }} fontSize="small" />
            </IconButton>
          )
        },
        width: 60,
      },
      { field: "rn", headerName: "順位", filter: "agNumberColumnFilter" },
      {
        field: "risk",
        headerName: "リスクレベル",
        filter: "agNumberColumnFilter",
      },
      { field: "intersection_name", headerName: "交差点名" },
      { field: "road_name", headerName: "名称" },
      { field: "address", headerName: "周辺住所" },
      { field: "zone30", headerName: "ゾーン30" },
      { field: "speed", headerName: "規制速度" },
      {
        field: "speed_median",
        headerName: "走行速度",
        filter: "agNumberColumnFilter",
      },
      { field: "pt0_2020", headerName: "人口", filter: "agNumberColumnFilter" },
    ],
    []
  )
  useEffect(() => {
    Rows(DataManager.getKosatenPredictionListQuery())
      .then((rows) => {
        setRowData(
          rows.sort((v1, v2) => {
            if (parseInt(v1["rn"]) < parseInt(v2["rn"])) return -1
            if (parseInt(v1["rn"]) > parseInt(v2["rn"])) return 1
            return 0
          })
        )
      })
      .catch((e) => {
        console.log(e)
      })
  }, [state.predictionMode, state.rangeType, state.mapViewBounds])

  return (
    <Box className={classes.root + " " + props.className}>
      <Typography className={classes.title}>交差点事故予測</Typography>
      <AgGridReact
        localeText={LOCALE_JA}
        className={"ag-theme-balham " + classes.grid}
        ref={gridRef}
        defaultColDef={{
          sortable: true,
          resizable: true,
          filter: true,
        }}
        columnDefs={columnDef}
        rowData={rowData}
        pagination={true}
        paginationAutoPageSize={true}
      />
    </Box>
  )
}

RootKosatenListView.propTypes = {
  className: PropTypes.string,
}

export default RootKosatenListView
