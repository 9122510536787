export const RangeType = {
  ViewBounds: "view_bounds",
  Overall: "overall",
}

export const RangeTypeTitle = {
  view_bounds: "表示範囲",
  overall: "市全体",
}

const RootViewDataState = {
  userUid: sessionStorage.getItem("user_uid"),
  viewType: "map",
  timeslotFilter: null,
  ageFilter: null,
  typeFilter: null,
  injuryFilter: null,
  viewData: ["道路事故予測", "交差点事故予測", "事故実績", "行政界"],
  predictionMode: "global",
  mapViewBounds: null,
  visibleRightPane: true,
  mapCenter: null,
  clickActionData: null,
  rangeType: RangeType.ViewBounds,
  pinLocation: null,
  loadingCount: 0,
  isCommentEditMode: false,
  commentEditData: null,

  openStateDialog: false,
  stateDialogTitle: null,
  stateDialogContent: null,
  stateDialogActions: null,
  stateDialogOnCLose: null,
}

export default RootViewDataState
