import carto from "@carto/carto.js"
import axios from "axios"
import PropTypes from "prop-types"
import { useEffect, useState } from "react"
import zIndex from "@mui/material/styles/zIndex"

export const cartClient = new carto.Client({
  apiKey: process.env.REACT_APP_CARTO_API_KEY,
  username: process.env.REACT_APP_CARTO_USERNAME,
  serverUrl: process.env.REACT_APP_CARTO_SERVER_URL,
})

export const CreateCartoQuerySource = (initialSql) =>
  new carto.source.SQL(initialSql)

export const CreateCartoCss = (initialStyle) =>
  new carto.style.CartoCSS(initialStyle)

export const GetLeafletLayer = (cartoLayer, options = {}) =>
  cartClient.getLeafletLayer({opacity: 0.3})

export const CreateCartoLayer = (source, style, options = {}) => {
  let layer = new carto.layer.Layer(source, style, options)
  cartClient.addLayer(layer).then(() => {})
  return layer
}

export const CreateCartoHistogramDataView = (source, column, bins = 5, mapBounds = null, options = {}, onChange = (() => {})) => {
  let ds = new carto.dataview.Histogram(source, column, {bins: bins, ...options})
  if (mapBounds) {
    let bboxFilter = new carto.filter.BoundingBox()
    bboxFilter.setBounds({west: mapBounds[1].lng, south: mapBounds[1].lat, east: mapBounds[0].lng, north: mapBounds[0].lat})
    ds.addFilter(bboxFilter)
  }
  ds.on("dataChanged", data => {
    onChange(data)
  })
  cartClient.addDataview(ds)
}

export const RemoveCartoLayer = async (cartoLayer) =>
  await cartClient.removeLayer(cartoLayer)

export const RemoveCartoDataView = async (dataview) => await cartClient.removeDataview(dataview)

export const Query = (sql, params = {}) => {
  return new Promise((resolve, reject) => {
    try {
      params["api_key"] = process.env.REACT_APP_CARTO_API_KEY
      if (!sql) {
        return resolve(null)
      }
      let queryString = sql
        .replace(/\n/g, " ")
        .replace(/ +/g, " ")
        .replace(/ +/g, " ")
      params["q"] = encodeURI(queryString)
      // &&の変換、+の変換
      params["q"] = params["q"].replace(/&&/g, "%26%26")
      params["q"] = params["q"].replace(/\+/g, "%2B")

      let queryValues = []
      Object.keys(params).forEach((v, i) =>
        queryValues.push(`${v}=${params[v]}`)
      )
      let url = `${
        process.env.REACT_APP_CARTO_SERVER_URL
      }/api/v2/sql?${queryValues.join("&")}`

      axios.get(url).then((res) => {
        resolve(res)
      })
    } catch (e) {
      reject(e)
    }
  })
}
export const Rows = (sql, params = {}) => {
  return new Promise((resolve, reject) => {
    Query(sql, params)
      .then((ret) => {
        resolve(ret?.data?.rows)
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export const First = (sql, params = {}) => {
  return new Promise((resolve, reject) => {
    Rows(sql, params)
      .then((ret) => {
        resolve(ret?.shift())
      })
      .catch((e) => {
        reject(e)
      })
  })
}

export const One = (sql, params = {}, columnName = null) => {
  return new Promise((resolve, reject) => {
    Query(sql, params)
      .then((ret) => {
        let colName = columnName ?? Object.keys(ret?.data?.fields ?? [])[0]
        let row = ret?.data?.rows?.shift()
        if (!row) {
          return resolve(null)
        }
        resolve(row[colName])
      })
      .catch((e) => {
        reject(e)
      })
  })
}
