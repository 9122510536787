import React, { useContext, useRef, useState } from "react"
import PropTypes from "prop-types"
import { makeStyles } from "@mui/styles"
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material"
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
} from "@mui/icons-material"
import RangeTypeDialog from "./rangeTypeDialog"
import { RootDataContext } from "../index"

const useStyles = makeStyles({
  root: {
    backgroundColor: "white",
    color: "#3c6960",
    border: "2px solid #3c6960",
    margin: "6px 8px",
    height: "30px",
    width: "160px",
    fontWeight: "bold",
  },
  arrowIcon: {
    position: "absolute",
    color: "#3c6960",
    right: "12px",
  },
})

const items = [
  { label: "レポート出力", value: "report" },
  { label: "データ出力", value: "export" },
  { label: "設定", value: "settings" },
  { label: "ヘルプ", value: "help" },
  { label: "ログアウト", value: "logout" },
]

const RootOptionView = (props) => {
  const classes = useStyles()
  const buttonRef = useRef()
  const { setUserUid } = useContext(RootDataContext)
  const [open, setOpen] = useState(false)
  const [openRangeTypeDialog, setOpenRangeTypeDialog] = useState(false)
  const [openLogoutDialog, setOpenLogoutDialog] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleLogoutDialogClose = () => {
    setOpenLogoutDialog(false)
  }

  const onClick = (value) => {
    switch (value) {
      case "range":
        setOpenRangeTypeDialog(true)
        break
      case "logout":
        setOpenLogoutDialog(true)
        break
      case "export":
        props.onExport()
        break
      default:
        break
    }
    handleClose()
  }

  return (
    <Box className={props.className + " " + classes.root}>
      <Button
        id="system_option"
        style={{ position: "relative", width: "100%", height: "100%" }}
        ref={buttonRef}
        onClick={handleOpen}
      >
        <Typography color="#3c6960" fontWeight="bold">
          オプション
        </Typography>
        {open && (
          <ArrowDropUpIcon fontSize="large" className={classes.arrowIcon} />
        )}
        {!open && (
          <ArrowDropDownIcon fontSize="large" className={classes.arrowIcon} />
        )}
      </Button>
      <Menu
        anchorEl={buttonRef.current}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "system_option",
        }}
        PaperProps={{
          style: {
            width: "180px",
            opacity: "0.95",
          },
        }}
      >
        {items.map((e, i) => {
          return (
            <MenuItem key={i} onClick={() => onClick(e.value)}>
              {e.label}
            </MenuItem>
          )
        })}
      </Menu>
      <RangeTypeDialog
        open={openRangeTypeDialog}
        onClose={() => setOpenRangeTypeDialog(false)}
      />
      <Dialog onClose={handleLogoutDialogClose} open={openLogoutDialog}>
        <DialogContent>本当にログアウトしますか</DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={props.onLogout}>はい</Button>
          <Button autoFocus={true} onClick={handleLogoutDialogClose}>
            キャンセル
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}

RootOptionView.propTypes = {
  className: PropTypes.string,
  onLogout: PropTypes.func,
  onExport: PropTypes.func,
}

export default RootOptionView
