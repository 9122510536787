import PropTypes from "prop-types"
import { makeStyles } from "@mui/styles"
import React, { useContext, useMemo, useRef, useState } from "react"
import { Box, Button, Menu, MenuItem, Typography } from "@mui/material"
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
  Check as CheckIcon,
} from "@mui/icons-material"
import { RangeType, RangeTypeTitle } from "../../data/state"
import {RootDataContext} from "../../index";

const useStyles = makeStyles({
  root: {
    backgroundColor: "white",
    border: "1px solid #3c6960",
    height: "30px",
    width: "220px",
    borderRadius: "14px",
    display: "flex",
    flexDirection: "row",
    margin: "auto",
    marginRight: "10px",
  },
  arrowIcon: {
    position: "absolute",
    color: "#3c6960",
    right: "12px",
  },
})

const RootRangeTypeSelectorView = (props) => {
  const classes = useStyles()
  const [open, setOpen] = useState(false)
  const buttonRef = useRef()

  const { state, setRangeType } = useContext(RootDataContext)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Box className={props.className + " " + classes.root}>
      <Button
        id="selector_prediction_mode"
        style={{ position: "relative", width: "100%" }}
        ref={buttonRef}
        onClick={handleOpen}
      >
        <Typography color="#3c6960" variant="subtitle2">
          統計範囲： {RangeTypeTitle[state.rangeType]}
        </Typography>
        {open && (
          <ArrowDropUpIcon fontSize="large" className={classes.arrowIcon} />
        )}
        {!open && (
          <ArrowDropDownIcon fontSize="large" className={classes.arrowIcon} />
        )}
      </Button>
      <Menu
        anchorEl={buttonRef.current}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "selector_prediction_mode",
        }}
        PaperProps={{
          style: {
            width: "180px",
          },
        }}
      >
        {[RangeType.ViewBounds, RangeType.Overall].map((v) => {
          return (
            <MenuItem
              key={v}
              onClick={() => {
                setRangeType(v)
                handleClose()
              }}
            >
              {RangeTypeTitle[v]} {state.rangeType === v && <CheckIcon />}
            </MenuItem>
          )
        })}
      </Menu>
    </Box>
  )
}

RootRangeTypeSelectorView.propTypes = {
  className: PropTypes.string,
}

export default RootRangeTypeSelectorView
