import PropTypes from "prop-types"
import { makeStyles } from "@mui/styles"
import React, { useContext, useEffect } from "react"
import { RootDataContext } from "../../../index"
import { Box } from "@mui/material"
import { Pie } from "react-chartjs-2"
import {ArcElement, Chart as ChartJS, Tooltip} from "chart.js";

ChartJS.register(ArcElement, Tooltip)

const useStyles = makeStyles({
  root: {
    color: "white",
  },
})

const RootAggregateCardPieChartView = (props) => {
  const classes = useStyles()

  return (
    <Box className={props.className + " " + classes.root}>
      <Pie data={props.data} />
    </Box>
  )
}

RootAggregateCardPieChartView.propTypes = {
  className: PropTypes.string,
  data: PropTypes.any,
}

export default RootAggregateCardPieChartView
