import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Box, Typography } from "@mui/material"
import { makeStyles } from "@mui/styles"
import { RootDataContext } from "../../../index"
import axios from "axios"
import { RangeType } from "../../../data/state"

const useStyles = makeStyles({
  root: {
    margin: "8px",
    padding: "4px 8px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
})

const RootTitleView = (props) => {
  const classes = useStyles()
  const { state } = useContext(RootDataContext)
  const [cityName, setCityName] = useState(null)

  useEffect(() => {
    if (!state.mapCenter) {
      return
    }
    axios
      .get(
        `https://gis.wpw.jp/api/v1/geocode/?ll=${state.mapCenter.lat},${state.mapCenter.lng}`
      )
      .then((res) => {
        if (!res.data.rows) {
          return
        }
        let result = res.data.rows[0]
        if (state.mapCenter.zoom < 10) {
          setCityName(null)
        } else if (state.mapCenter.zoom < 12) {
          setCityName(result["pref_name"])
        } else if (state.mapCenter.zoom > 14) {
          setCityName(result["city_name"] + (result["s_name"] ?? ""))
        } else {
          setCityName(result["city_name"])
        }
      })
      .catch((e) => {
        console.log("[address]", "error", e)
      })
  }, [state.mapCenter])

  return (
    <Box className={props.className + " " + classes.root} style={props.style}>
      {state.rangeType === RangeType.Overall && (
        <>
          <Typography
            variant="h6"
            fontWeight="bold"
            style={{ marginRight: "8px" }}
          >
            {process.env.REACT_APP_CITYNAME}
          </Typography>
          <Typography>全体の</Typography>
        </>
      )}
      {state.rangeType === RangeType.ViewBounds && cityName && (
        <>
          <Typography
            variant="h6"
            fontWeight="bold"
            style={{ marginRight: "8px" }}
          >
            {cityName}
          </Typography>
          <Typography>周辺の</Typography>
        </>
      )}
      <Typography>交通事故実績と事故発生リスク </Typography>
    </Box>
  )
}

RootTitleView.propTypes = {
  className: PropTypes.string,
  style: PropTypes.any,
}

export default RootTitleView
